@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;

    &:not(:first-child) {
      margin: 0;
    }
  }

  li::marker {
    display: none;
  }
}
